import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  useDeleteDoctorMutation,
  useDeleteMedicineMutation,
  useDeletePharmacyMutation,
  useDeletePlanMutation,
} from "../../../redux/services/API";

import { Theme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";

import AddLinkIcon from "@mui/icons-material/AddLink";
import EmailIcon from "@mui/icons-material/Email";
import Cancel from "@mui/icons-material/Cancel";

import EmailQuote from "../../shared/EmailQuote";

import copyTextToClipboard from "../../../utils/copyTextToClipBoard";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";
import useNavigateParams from "../../../utils/useNavigateParams";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { SelectedDoctor } from "../../../types/Doctor.types";
import { PlanFavorite } from "../../../types/Plan.types";
import { SelectedMedicine } from "../../../types/Medicine.types";
import { SelectedPharmacy } from "../../../types/Pharmacy.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

interface RootProps {
  open: boolean;
  handleClose: () => void;
}
export default function QuotePopup({ open, handleClose }: RootProps) {
  const navigate = useNavigateParams();
  const { Doctors, Medicines, Pharmacy, FavoritePlans, QuoteID, SessionID } =
    useAppSelector((state) => state.Quote);
  const AffiliateCode = useAppSelector((state) => state.Affiliate.Code);

  const analytics = useRudderStackAnalytics();

  const [deleteDoctor, { reset: resetDeleteDoctor }] =
    useDeleteDoctorMutation();

  const [
    removeMedicine,
    { isLoading: isDeletingMedicine, reset: resetRemoveMedicine },
  ] = useDeleteMedicineMutation();

  const [
    deletePharmacy,
    { reset: resetDeletePharmacy, isLoading: isDeletingPharmacy },
  ] = useDeletePharmacyMutation();

  const [deletePlan, { reset: resetDeletePlan }] = useDeletePlanMutation();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const [isCopied, setIsCopied] = React.useState(false);
  const [openEmailQuote, setOpenEmailQuote] = React.useState(false);

  React.useEffect(() => {
    if (!QuoteID) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.QUOTE_INFO_OPENED, {
      quote_id: QuoteID,
    });

    return () => {
      analytics?.track(ANALYTICS_EVENT.QUOTE_INFO_CLOSED, {
        quote_id: QuoteID,
      });
    };
  }, [QuoteID, analytics]);

  const URL = `https://${window.location.hostname}/${AffiliateCode}?quote=${QuoteID}`;

  const handleCopyClick = () => {
    copyTextToClipboard(URL)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });

    analytics?.track(ANALYTICS_EVENT.QUOTE_URL_COPIED, {
      quote_id: QuoteID,
      link_url: URL,
    });
  };

  const handlePlanClick = (plan: PlanFavorite) => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_url: `/input/compare/plan-details/${plan.ID}`,
      link_text: plan.PlanName,
    });

    handleClose();
    navigate(`/input/compare/plan-details/${plan.ID}`);
  };

  const handleRemoveDoctor = (doctor: SelectedDoctor) => {
    const { firstName, lastName, facilityName, providerId, npi } = doctor;

    deleteDoctor({
      QuoteID: QuoteID ?? "",
      DoctorID: providerId,
    });
    resetDeleteDoctor();

    analytics?.track(ANALYTICS_EVENT.QUOTE_DOCTOR_REMOVED, {
      quote_id: QuoteID,
      doctor_name: facilityName ? facilityName : `${firstName} ${lastName}`,
      doctor_npi: npi,
      doctor_address: doctor.location.address,
      doctor_address_2: doctor.location.address2,
      doctor_city: doctor.location.city,
      doctor_state: doctor.location.state,
      doctor_zip_code: doctor.location.zip,
    });
  };

  const handleRemoveMedicine = (medicine: SelectedMedicine) => {
    removeMedicine({
      SessionID: SessionID ?? "",
      DosageRecordID: medicine.DosageRecordID,
    });

    resetRemoveMedicine();

    analytics?.track(ANALYTICS_EVENT.QUOTE_MEDICINE_REMOVED, {
      quote_id: QuoteID,
      medicine_ndc: medicine.NDC,
      medicine_name: medicine.LabelName,
      medicine_dosage: medicine.LabelName,
      medicine_quantity: medicine.UserQuantity,
      medicine_day_supply: medicine.DaysOfSupply,
    });
  };

  const handleRemovePharmacy = (pharmacy: SelectedPharmacy) => {
    deletePharmacy({
      SessionID: SessionID ?? "",
      PharmacyRecordID: pharmacy.PharmacyRecordID ?? -10,
    });
    resetDeletePharmacy();

    analytics?.track(ANALYTICS_EVENT.QUOTE_PHARMACY_REMOVED, {
      quote_id: QuoteID,
      pharmacy_name: pharmacy.Name,
      pharmacy_id: pharmacy.PharmacyID,
      pharmacy_address: pharmacy.Address1,
      pharmacy_address_2: pharmacy.Address2,
      pharmacy_city: pharmacy.City,
      pharmacy_state: pharmacy.State,
      pharmacy_zip_code: pharmacy.Zip,
    });
  };

  const handleRemovePlan = (plan: PlanFavorite) => {
    deletePlan({
      QuoteID: QuoteID ?? "",
      PlanID: plan.ID,
    });
    resetDeletePlan();

    analytics?.track(ANALYTICS_EVENT.QUOTE_FAVORITE_PLAN_REMOVED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SnpType,
      plan_type: plan.PlanType,
    });
  };

  const onDialogClose = () => {
    handleClose();
    setOpenEmailQuote(false);
  };

  const handleEmailQuoteClick = () => {
    setOpenEmailQuote((prev) => !prev);
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      maxWidth="sm"
      open={open}
      onClose={() => onDialogClose()}
      scroll="paper"
      PaperProps={{ sx: { borderRadius: 3 } }}
    >
      <DialogTitle
        sx={{
          px: 2,
          pt: 6,
          pb: 2,
          bgcolor: "primary.main",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 6,
            top: 6,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
        <Typography fontWeight={600} variant="h4">
          Your Quote
        </Typography>
      </DialogTitle>

      <Grid
        container
        sx={{ px: { xs: 1, sm: 5 }, mt: { xs: 1, sm: 5 }, mb: 0 }}
      >
        <Grid item xs={12} container direction="column" textAlign="center">
          <Grid item>
            <Typography fontWeight={600} variant="h4">
              Quote ID:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" fontWeight={500} color="GrayText">
              {QuoteID}
            </Typography>
          </Grid>

          <Grid item container justifyContent="center">
            <IconButton
              title={isCopied ? "Copied" : "Copy Link"}
              onClick={() => handleCopyClick()}
            >
              <AddLinkIcon color="primary" fontSize="large" />
            </IconButton>
            <IconButton
              title="Email Quote ID"
              onClick={() => handleEmailQuoteClick()}
            >
              <EmailIcon color="primary" fontSize="large" />
            </IconButton>
          </Grid>
          {openEmailQuote && (
            <Grid item container justifyContent="center">
              <EmailQuote />
            </Grid>
          )}
        </Grid>
      </Grid>

      <DialogContent>
        <Grid container sx={{ p: { xs: 0, sm: 3, md: 5 } }} rowGap={1}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={600}>
              Doctors
            </Typography>
            <Divider flexItem sx={{ borderBottomWidth: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />}>
              {Doctors && Doctors.length > 0 ? (
                Doctors.map((doctor) => {
                  return (
                    <Grid
                      key={doctor.providerId}
                      container
                      px={0.5}
                      py={1}
                      alignItems="center"
                    >
                      <Grid item xs={10} container direction="column">
                        <Typography fontWeight={600}>
                          {doctor.facilityName
                            ? doctor.facilityName
                            : `Dr. ${doctor.firstName} ${doctor.lastName}`}
                        </Typography>
                        <Typography variant="body2">
                          {doctor.facilityName ? "Facility" : "Individual"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent="flex-end">
                        <IconButton
                          title="Remove Doctor"
                          onClick={() => handleRemoveDoctor(doctor)}
                        >
                          <Cancel color="error" fontSize="large" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Typography fontWeight={600}>No Doctors Selected</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={600}>
              Medicines
            </Typography>
            <Divider flexItem sx={{ borderBottomWidth: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />}>
              {Medicines && Medicines.length > 0 ? (
                Medicines.map((medicine) => {
                  return (
                    <Grid
                      key={medicine.NDC}
                      container
                      px={0.5}
                      py={1}
                      alignItems="center"
                    >
                      <Grid item xs={10} container direction="column">
                        <Typography fontWeight={600}>
                          {medicine.LabelName}
                        </Typography>
                        <Typography variant="body2">
                          {medicine.UserQuantity} per {medicine.DaysOfSupply}{" "}
                          days
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent="flex-end">
                        <IconButton
                          title="Remove Medicine"
                          onClick={() => handleRemoveMedicine(medicine)}
                          disabled={isDeletingMedicine}
                        >
                          <Cancel color="error" fontSize="large" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Typography fontWeight={600}>No Medicines Selected</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={600}>
              Pharmacy
            </Typography>
            <Divider flexItem sx={{ borderBottomWidth: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />}>
              {Pharmacy ? (
                <Grid container px={0.5} py={1} alignItems="center">
                  <Grid item xs={10} container direction="column">
                    <Typography fontWeight={600}>{Pharmacy.Name}</Typography>
                    <Typography variant="body2">
                      {Pharmacy.Address1},{" "}
                      {Pharmacy.Address2 ? `${Pharmacy.Address2}, ` : null}
                      {Pharmacy.Zip}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} container justifyContent="flex-end">
                    <IconButton
                      title="Remove Pharmacy"
                      onClick={() => handleRemovePharmacy(Pharmacy)}
                      disabled={isDeletingPharmacy}
                    >
                      <Cancel color="error" fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Typography fontWeight={600}>No Pharmacy Selected</Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={600}>
              Plans
            </Typography>
            <Typography variant="body2">
              *Click Plan Name for Details
            </Typography>
            <Divider flexItem sx={{ borderBottomWidth: 2 }} />
          </Grid>

          <Grid item xs={12}>
            <Stack divider={<Divider flexItem />}>
              {FavoritePlans && FavoritePlans.length > 0 ? (
                FavoritePlans.map((plan) => {
                  return (
                    <Grid
                      key={plan.ID}
                      container
                      px={0.5}
                      py={1}
                      alignItems="center"
                    >
                      <Grid item xs={10} container direction="column" gap={1}>
                        <img
                          src={`${DRX_LOGO_URL}/${plan.LogoURL}`}
                          alt=""
                          width="150px"
                          height="auto"
                          loading="lazy"
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "secondary.dark",
                          }}
                          onClick={() => handlePlanClick(plan)}
                        >
                          {plan.PlanName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} container justifyContent="flex-end">
                        <IconButton
                          title="Remove Plan"
                          onClick={() => handleRemovePlan(plan)}
                        >
                          <Cancel color="error" fontSize="large" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Typography fontWeight={600}>No Favorite Plans</Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
