import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { add, format, startOfMonth, startOfYear } from "date-fns";

import { DEFAULT_PLAN_YEAR } from "../../config/global.config";

import { DoctorNetwork } from "../../types/Doctor.types";
import { PlanSortType, PlanTypeTabs } from "../../types/Plan.types";

interface Metadata {
  Carriers: string[];
  PlanSubTypes: string[];
  PlanFeatures: string[];
  TotalPlans: number;
}

interface PlanState {
  DoctorNetworks: DoctorNetwork[] | null;
  PlanMetadata: {
    [key: string]: Metadata;
  };
  PlanFilters: {
    Carriers: {
      [key: string]: boolean;
    };
    MonthlyPremium: number[];
    PlanFeatures: {
      [key: string]: boolean;
    };
    PlanSubTypes: {
      [key: string]: boolean;
    };
  };
  PlanSort: PlanSortType;
  PlanTypeTab: PlanTypeTabs;
  EffectiveDate: string;
  PlanYear: number;

  MedSupp: {
    Metadata: {
      PlanNames: string[];
      Carriers: string[];
      TotalPlans: number;
    };
    PlanFilters: {
      Carriers: string[];
      Premium: number[];
    };
  };
}

const initialState: PlanState = {
  DoctorNetworks: null,
  PlanMetadata: {},
  PlanFilters: {
    Carriers: {},
    MonthlyPremium: [0, 1000],
    PlanFeatures: {},
    PlanSubTypes: {},
  },
  PlanSort: PlanSortType.BEST_MATCH,
  PlanTypeTab: PlanTypeTabs.MAPD,
  EffectiveDate:
    DEFAULT_PLAN_YEAR > new Date().getFullYear()
      ? format(startOfYear(add(new Date(), { years: 1 })), "M/d/yyyy")
      : format(startOfMonth(add(new Date(), { months: 1 })), "M/d/yyyy"),
  PlanYear: DEFAULT_PLAN_YEAR,

  MedSupp: {
    Metadata: {
      PlanNames: [],
      Carriers: [],
      TotalPlans: 0,
    },
    PlanFilters: {
      Carriers: [],
      Premium: [0, 1000],
    },
  },
};

export const planSlice = createSlice({
  name: "Plan",
  initialState,
  reducers: {
    setPlanMetaData(
      state: PlanState,
      action: PayloadAction<{
        planYear: number;
        planType: string;
        metadata: Metadata;
      }>
    ) {
      state.PlanMetadata[
        `${action.payload.planYear}-${action.payload.planType}`
      ] = action.payload.metadata;
      state.PlanFilters.Carriers = action.payload.metadata.Carriers.reduce(
        (ac, a) => ({ ...ac, [a]: false }),
        {}
      );
      state.PlanFilters.PlanSubTypes =
        action.payload.metadata.PlanSubTypes.reduce(
          (ac, a) => ({ ...ac, [a]: false }),
          {}
        );
      state.PlanFilters.MonthlyPremium = [0, 1000];
      state.PlanFilters.PlanFeatures =
        action.payload.metadata.PlanFeatures.reduce(
          (ac, a) => ({ ...ac, [a]: false }),
          {}
        );
      state.PlanSort = PlanSortType.BEST_MATCH;
    },
    setDoctorNetworks: (
      state: PlanState,
      action: PayloadAction<DoctorNetwork[]>
    ) => {
      state.DoctorNetworks = action.payload;
    },
    togglePlanSubType: (state: PlanState, action: PayloadAction<string>) => {
      state.PlanFilters.PlanSubTypes[action.payload] =
        !state.PlanFilters.PlanSubTypes[action.payload];
    },
    toggleCarrier: (state: PlanState, action: PayloadAction<string>) => {
      state.PlanFilters.Carriers[action.payload] =
        !state.PlanFilters.Carriers[action.payload];
    },
    clearCarrierFilters: (state: PlanState) => {
      state.PlanFilters.Carriers = Object.fromEntries(
        Object.keys(state.PlanFilters.Carriers).map((key) => [key, false])
      );
    },
    togglePlanFeature: (state: PlanState, action: PayloadAction<string>) => {
      state.PlanFilters.PlanFeatures[action.payload] =
        !state.PlanFilters.PlanFeatures[action.payload];
    },
    setMonthlyPremium: (state: PlanState, action: PayloadAction<number[]>) => {
      state.PlanFilters.MonthlyPremium = action.payload;
    },
    setPlanSortType: (
      state: PlanState,
      action: PayloadAction<PlanSortType>
    ) => {
      state.PlanSort = action.payload;
    },
    resetAllPlanFilters: (state: PlanState, action: PayloadAction<string>) => {
      const planMetadata = state.PlanMetadata[action.payload];

      state.PlanFilters.Carriers = planMetadata
        ? planMetadata.Carriers.reduce((ac, a) => ({ ...ac, [a]: false }), {})
        : {};

      state.PlanFilters.PlanSubTypes = planMetadata
        ? planMetadata.PlanSubTypes.reduce(
            (ac, a) => ({ ...ac, [a]: false }),
            {}
          )
        : {};

      state.PlanFilters.MonthlyPremium = [0, 1000];

      state.PlanFilters.PlanFeatures = planMetadata
        ? planMetadata.PlanFeatures.reduce(
            (ac, a) => ({ ...ac, [a]: false }),
            {}
          )
        : {};
      state.PlanSort = PlanSortType.BEST_MATCH;
    },
    setPlanTypeTab: (state: PlanState, action: PayloadAction<PlanTypeTabs>) => {
      state.PlanTypeTab = action.payload;
    },
    setEffectiveDate: (state: PlanState, action: PayloadAction<string>) => {
      state.EffectiveDate = action.payload;
    },
    setPlanYear: (state: PlanState, action: PayloadAction<number>) => {
      state.PlanYear = action.payload;
    },

    setMedSuppPlanNames: (
      state: PlanState,
      action: PayloadAction<string[]>
    ) => {
      state.MedSupp.Metadata.PlanNames = action.payload;
    },
    setMedSuppCarriers: (state: PlanState, action: PayloadAction<string[]>) => {
      state.MedSupp.Metadata.Carriers = action.payload;
    },
    setMedSuppCarrierFilter: (
      state: PlanState,
      action: PayloadAction<string[]>
    ) => {
      state.MedSupp.PlanFilters.Carriers = action.payload;
    },
    removeMedSuppCarrierFilter: (
      state: PlanState,
      action: PayloadAction<string>
    ) => {
      const index = state.MedSupp.PlanFilters.Carriers.findIndex(
        (carrier) => carrier === action.payload
      );
      state.MedSupp.PlanFilters.Carriers.splice(index, 1);
    },
    setMedSuppPremiumFilter: (
      state: PlanState,
      action: PayloadAction<number[]>
    ) => {
      state.MedSupp.PlanFilters.Premium = action.payload;
    },
  },
});

export const {
  setPlanMetaData,
  setDoctorNetworks,
  togglePlanSubType,
  toggleCarrier,
  clearCarrierFilters,
  togglePlanFeature,
  setMonthlyPremium,
  resetAllPlanFilters,
  setPlanSortType,
  setPlanTypeTab,
  setEffectiveDate,
  setPlanYear,
  setMedSuppPlanNames,
  setMedSuppCarriers,
  setMedSuppCarrierFilter,
  setMedSuppPremiumFilter,
  removeMedSuppCarrierFilter,
} = planSlice.actions;
export default planSlice.reducer;
