import * as React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/material/styles";

import Lightbulb from "@mui/icons-material/Lightbulb";
import Medication from "@mui/icons-material/Medication";
import Article from "@mui/icons-material/Article";
import Edit from "@mui/icons-material/Edit";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import ArrowForward from "@mui/icons-material/ArrowForward";

import { PlanDocuments } from "../../PlanDetails/DetailComponents";
import { ComparisonSubBenefit } from "../PlanComparisonComponents";
import StarRating from "../../../shared/StarRating";

import numberWithCommas from "../../../../utils/numberWithCommas";
import { formatUSD } from "../../../../utils/formatUSD";

import { Plan } from "../../../../types/Plan.types";

const DRX_LOGO_URL = process.env.REACT_APP_DRX_LOGO_URL;

interface RootProps {
  planOne: Plan;
  planTwo: Plan;
  handleEditDrugs: () => void;
  handleShowDrugCosts: (plan: Plan) => void;
}

export default function PdpComparison({
  planOne,
  planTwo,
  handleEditDrugs,
  handleShowDrugCosts,
}: RootProps) {
  const [type, setType] = React.useState("Retail-30");
  const [PharmacyType, DaySupply] = type.split("-");

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  return (
    <Grid container direction="column" rowGap={4}>
      <Grid item container justifyContent="space-evenly">
        <Grid
          key={`plan-${planOne.ID}`}
          item
          xs={6}
          container
          direction="column"
          pl={1}
          rowGap={1}
        >
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                p: 0.5,
                width: 125,
                height: 32,
              })}
              fontWeight={600}
            >
              {planOne.PlanYear}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${planOne.LogoURL}`}
              alt=""
              width="150px"
              height="75px"
              loading="lazy"
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={600}>
              {planOne.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            {planOne.PlanRating ? (
              <StarRating rating={planOne.PlanRating} />
            ) : (
              <Typography variant="body2">No Star Rating</Typography>
            )}
          </Grid>
        </Grid>

        <Grid
          key={`plan-${planTwo.ID}`}
          item
          xs={6}
          container
          direction="column"
          pl={1}
          rowGap={1}
        >
          <Grid item>
            <Typography
              textAlign="center"
              sx={(theme) => ({
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                p: 0.5,
                width: 125,
                height: 32,
              })}
              fontWeight={600}
            >
              {planTwo.PlanYear}
            </Typography>
          </Grid>
          <Grid item>
            <img
              src={`${DRX_LOGO_URL}/${planTwo.LogoURL}`}
              alt=""
              width="150px"
              height="75px"
              loading="lazy"
              style={{ objectFit: "contain", objectPosition: "left" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" fontWeight={600}>
              {planTwo.PlanName}
            </Typography>
          </Grid>
          <Grid item>
            {planTwo.PlanRating ? (
              <StarRating rating={planTwo.PlanRating} />
            ) : (
              <Typography variant="body2">No Star Rating</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container justifyContent="center">
        <Divider flexItem sx={{ width: "90%" }} />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <Lightbulb fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Plan Highlights
          </Typography>
        </Grid>

        <ComparisonSubBenefit
          title="Monthly Plan Premium"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: `$${(
                    planOne.MedicalPremium + planOne.DrugPremium
                  )?.toFixed(2)}`,
                },
                {
                  value: `$${(
                    planTwo.MedicalPremium + planTwo.DrugPremium
                  )?.toFixed(2)}`,
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Drug Deductible"
          subsections={[
            {
              title: "Annual",
              values: [
                {
                  value: `$${numberWithCommas(planOne.DrugDeductible)}`,
                },
                {
                  value: `$${numberWithCommas(planTwo.DrugDeductible)}`,
                },
              ],
            },
          ]}
        />
      </Grid>

      <Grid item container direction="column" rowGap={2}>
        <Grid item display="inline-flex" alignItems="center">
          <Medication fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Prescriptions Benefits
          </Typography>
        </Grid>

        <ComparisonSubBenefit
          title="Drug Deductible"
          subsections={[
            {
              title: "",
              values: [
                { value: `$${planOne.DrugDeductible}` },
                { value: `$${planTwo.DrugDeductible}` },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Est. Monthly Drug Costs"
          subsections={[
            {
              title: "",
              values: [
                {
                  value:
                    planOne.PlanDrugCoverage?.length > 0 &&
                    planOne.EstimatedMonthlyDrugCosts !== undefined
                      ? `${formatUSD(
                          planOne.EstimatedMonthlyDrugCosts -
                            planOne.DrugPremium
                        )}`
                      : "N/A",
                },
                {
                  value:
                    planTwo.PlanDrugCoverage?.length > 0 &&
                    planTwo.EstimatedMonthlyDrugCosts !== undefined
                      ? `${formatUSD(
                          planTwo.EstimatedMonthlyDrugCosts -
                            planTwo.DrugPremium
                        )}`
                      : "N/A",
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Pharmacy Type"
          subsections={[
            {
              title: "",
              values: [
                {
                  value: planOne.PharmacyCosts[0]?.IsPreferred
                    ? "Preferred Cost-Sharing Pharmacy"
                    : "Standard Cost-Sharing Pharmacy",
                },
                {
                  value: planTwo.PharmacyCosts[0]?.IsPreferred
                    ? "Preferred Cost-Sharing Pharmacy"
                    : "Standard Cost-Sharing Pharmacy",
                },
              ],
            },
          ]}
        />

        <ComparisonSubBenefit
          title="Your Drug List"
          icon={
            <Button
              variant="text"
              color="secondary"
              endIcon={<Edit />}
              onClick={() => handleEditDrugs()}
            >
              Edit
            </Button>
          }
          subsections={
            planOne.PlanDrugCoverage && planOne.PlanDrugCoverage.length > 0
              ? planOne.PlanDrugCoverage.map((drugCoverage) => {
                  const drugCoveredPlanOne = drugCoverage.TierNumber > 0;
                  const drugCoveragePlanTwo = planTwo.PlanDrugCoverage.find(
                    (drug) => drug.NDC === drugCoverage.NDC
                  );

                  const drugCoveredPlanTwo = drugCoveragePlanTwo
                    ? drugCoveragePlanTwo.TierNumber > 0
                    : false;

                  return {
                    title: drugCoverage.LabelName.toUpperCase(),
                    values: [
                      {
                        value: drugCoveredPlanOne ? "Covered" : "Not Covered",
                        icon: drugCoveredPlanOne ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Cancel color="error" />
                        ),
                      },
                      {
                        value: drugCoveredPlanTwo ? "Covered" : "Not Covered",
                        icon: drugCoveredPlanTwo ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <Cancel color="error" />
                        ),
                      },
                    ],
                  };
                })
              : [
                  {
                    title: "No Drugs Selected",
                    values: [{ value: "" }, { value: "" }],
                  },
                ]
          }
        />
        <Grid item container justifyContent="space-evenly">
          <Grid item xs={6}>
            <Button
              variant="text"
              color="secondary"
              endIcon={<ArrowForward />}
              onClick={() => handleShowDrugCosts(planOne)}
            >
              View Drug Costs
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="text"
              color="secondary"
              endIcon={<ArrowForward />}
              onClick={() => handleShowDrugCosts(planTwo)}
            >
              View Drug Costs
            </Button>
          </Grid>
        </Grid>

        <Grid item display="flex" height={40}>
          <Select value={type} onChange={handleTypeChange} variant="standard">
            <MenuItem value={"Retail-30"}>30 Day - Retail</MenuItem>
            <MenuItem value={"Retail-90"}>90 Day - Retail</MenuItem>
            <MenuItem value={"MailOrder-30"}>30 Day - Mail-Order</MenuItem>
            <MenuItem value={"MailOrder-90"}>90 Day - Mail-Order</MenuItem>
          </Select>
        </Grid>

        <ComparisonSubBenefit
          title="Drug Tier Pricing - Preferred Pharmacy"
          subsections={
            planOne.DrugTier
              ? Object.keys(
                  //@ts-ignore
                  planOne.DrugTier.Preferred[PharmacyType][DaySupply]
                ).map((key) => {
                  const valuePlanOne =
                    //@ts-ignore
                    planOne.DrugTier.Preferred[PharmacyType][DaySupply][key];

                  const valuePlanTwo =
                    //@ts-ignore
                    planTwo.DrugTier.Preferred[PharmacyType][DaySupply][key];
                  return {
                    title: `Tier ${key}`,
                    values: [{ value: valuePlanOne }, { value: valuePlanTwo }],
                  };
                })
              : []
          }
        />

        <ComparisonSubBenefit
          title="Drug Tier Pricing - Standard Pharmacy"
          subsections={
            planOne.DrugTier
              ? Object.keys(
                  //@ts-ignore
                  planOne.DrugTier.Standard[PharmacyType][DaySupply]
                ).map((key) => {
                  const valuePlanOne =
                    //@ts-ignore
                    planOne.DrugTier.Standard[PharmacyType][DaySupply][key];

                  const valuePlanTwo =
                    //@ts-ignore
                    planTwo.DrugTier.Standard[PharmacyType][DaySupply][key];
                  return {
                    title: `Tier ${key}`,
                    values: [{ value: valuePlanOne }, { value: valuePlanTwo }],
                  };
                })
              : []
          }
        />
      </Grid>

      <Grid item container rowGap={2}>
        <Grid item xs={12} display="inline-flex" alignItems="center">
          <Article fontSize="large" color="secondary" />
          <Typography variant="h6" fontWeight={700}>
            Plan Documents
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          gap={1}
          pl={2}
        >
          <PlanDocuments
            plan={planOne}
            documents={
              planOne.Documents
                ? planOne.Documents.map((document) => {
                    return {
                      title: document.LinkName,
                      link: document.Url,
                    };
                  })
                : []
            }
          />
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="column"
          alignItems="flex-start"
          gap={1}
          pl={2}
        >
          <PlanDocuments
            plan={planTwo}
            documents={
              planTwo.Documents
                ? planTwo.Documents.map((document) => {
                    return {
                      title: document.LinkName,
                      link: document.Url,
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
