import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setActiveStep } from "../../../redux/reducers/Quote";
import { createSelector } from "@reduxjs/toolkit";

import {
  useDeletePlanMutation,
  useGetDoctorsNetworksQuery,
  useGetPlansInAreaQuery,
  useSavePlanMutation,
} from "../../../redux/services/API";

import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme, Typography } from "@mui/material";

import InfoBox from "../InfoBox";
import PlanComparisonDialog from "../PlanComparisonDialog";
import DrugCostsDialog from "../DrugCostsDialog";
import CompareBar from "../CompareBar";

import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import {
  ComparePlan,
  Plan,
  PlanFavorite,
  PlanSortType,
  PlanTypeTabs,
  PlanType,
} from "../../../types/Plan.types";
import { STEPS } from "../../../types/Global.types";
import { WEBSITE_FILTER_TYPE } from "../../../types/Affiliate.types";
import useNavigateParams from "../../../utils/useNavigateParams";

import { PLAN_TABS_INFO } from "../Compare.layout";
import { ANALYTICS_EVENT } from "../../../config/analytics.config";

const MACard = React.lazy(() => import("../PlanCards/MACard"));
const MAPDCard = React.lazy(() => import("../PlanCards/MAPDCard"));
const PDPCard = React.lazy(() => import("../PlanCards/PDPCard"));

const emptyArray: Plan[] = [];

interface RootProps {
  isPending: boolean;
}

export default function MedAdvContainer({ isPending }: RootProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigateParams();
  const analytics = useRudderStackAnalytics();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  /**
   * PlanComparisonDialog Max amount of Plans
   * Mobile/Tablet: Max 2 Plans
   * Desktop: Max 2 Plans
   */
  const maxComparePlans = isMobile ? 2 : 2;

  const {
    SessionID,
    QuoteID,
    Doctors: selectedDoctors,
    Location: location,
    FavoritePlans: favoritePlans,
  } = useAppSelector((state) => state.Quote);

  const { [WEBSITE_FILTER_TYPE.PLAN]: PlanInAreaFilters } = useAppSelector(
    (state) => state.Affiliate.Filters
  );
  const {
    PlanSort,
    PlanFilters,
    PlanTypeTab,
    EffectiveDate,
    PlanYear,
    DoctorNetworks,
  } = useAppSelector((state) => state.Plan);
  const { Carriers, PlanSubTypes, PlanFeatures, MonthlyPremium } = PlanFilters;

  const allFilters = React.useMemo(() => {
    const { Carriers, PlanSubTypes, PlanFeatures, MonthlyPremium } =
      PlanFilters;

    let allFilters: Array<{ type: string; value: any }> = [
      {
        type: "PlanType",
        value: PLAN_TABS_INFO[PlanTypeTab].id,
      },
      {
        type: "PlanYear",
        value: PlanYear,
      },
    ];

    Object.keys(Carriers).forEach((key) => {
      const value = Carriers[key];

      if (value) {
        allFilters.push({
          type: "CarrierName",
          value: key,
        });
      }
    });

    Object.keys(PlanSubTypes).forEach((key) => {
      const value = PlanSubTypes[key];

      if (value) {
        allFilters.push({
          type: "PlanSubType",
          value: key,
        });
      }
    });

    Object.keys(PlanFeatures).forEach((key) => {
      const value = PlanFeatures[key];

      if (value) {
        allFilters.push({
          type: "PlanFeature",
          value: key,
        });
      }
    });

    allFilters.push({
      type: "Premium",
      value: `${MonthlyPremium[0]}-${MonthlyPremium[1]}`,
    });

    return allFilters;
  }, [PlanFilters, PlanTypeTab, PlanYear]);

  const filterPlanData = React.useMemo(() => {
    return createSelector(
      (res: Plan[]) => res,
      (data: Plan[]) => {
        if (!data) {
          return [];
        }

        analytics?.track(ANALYTICS_EVENT.SEARCH_STARTED, {
          quote_id: QuoteID,
          search_type: "MED_ADV_PLANS",
          search_keyword: "",
          search_filters: allFilters,
          search_sorts: [{ type: PlanSortType[PlanSort], value: "desc" }],
          search_meta: [
            {
              type: "page",
              value: 1,
            },
          ],
        });

        let filterData = data;

        const carrierFilters: string[] = [];
        Object.keys(Carriers).forEach((key) => {
          const value = Carriers[key];

          if (value) {
            carrierFilters.push(key);
          }
        });

        filterData =
          carrierFilters.length > 0
            ? filterData.filter((plan) => {
                const fieldFilter = plan.MarketingName;
                return carrierFilters.includes(fieldFilter);
              })
            : filterData;

        const planSubTypeFilters: string[] = [];
        Object.keys(PlanSubTypes).forEach((key) => {
          const value = PlanSubTypes[key];

          if (value) {
            planSubTypeFilters.push(key);
          }
        });

        filterData =
          planSubTypeFilters.length > 0
            ? filterData.filter((plan) =>
                planSubTypeFilters.includes(plan.PlanSubType)
              )
            : filterData;

        const planFeaturesFilters: string[] = [];
        Object.keys(PlanFeatures).forEach((key) => {
          const value = PlanFeatures[key];

          if (value) {
            planFeaturesFilters.push(key);
          }
        });

        filterData =
          planFeaturesFilters.length > 0
            ? filterData.filter((plan) => {
                for (let planFeature of planFeaturesFilters) {
                  //@ts-ignore
                  if (!plan.Ancillary[planFeature]) {
                    return false;
                  }
                }

                return true;
              })
            : filterData;

        filterData = filterData.filter((plan) => {
          let monthlyPremium = plan.MedicalPremium + plan.DrugPremium;

          return (
            monthlyPremium >= MonthlyPremium[0] &&
            monthlyPremium <= MonthlyPremium[1]
          );
        });

        /**
         * Sort By
         */
        let sortedData = filterData;

        switch (PlanSort) {
          case PlanSortType.BEST_MATCH:
            sortedData = filterData.sort((planA, planB) => {
              let doctorsInNeworkPlanA = 0;
              let doctorsInNeworkPlanB = 0;

              DoctorNetworks?.forEach((doctor) => {
                doctor.location.networks?.forEach((network) => {
                  if (network.id === planA.ZelisNetwork) {
                    doctorsInNeworkPlanA += 1;
                  }

                  if (network.id === planB.ZelisNetwork) {
                    doctorsInNeworkPlanB += 1;
                  }
                });
              });

              let monthlyPremiumPlanA =
                planA.MedicalPremium + planA.DrugPremium;

              let monthlyPremiumPlanB =
                planB.MedicalPremium + planB.DrugPremium;

              let monthlyDrugCostsPlanA =
                planA.EstimatedMonthlyDrugCosts !== undefined
                  ? planA.EstimatedMonthlyDrugCosts - planA.DrugPremium
                  : -1;
              let monthlyDrugCostsPlanB =
                planB.EstimatedMonthlyDrugCosts !== undefined
                  ? planB.EstimatedMonthlyDrugCosts - planB.DrugPremium
                  : -1;

              return (
                doctorsInNeworkPlanB - doctorsInNeworkPlanA ||
                planB.Points - planA.Points ||
                monthlyPremiumPlanA - monthlyPremiumPlanB ||
                monthlyDrugCostsPlanA - monthlyDrugCostsPlanB
              );
            });
            break;

          case PlanSortType.CARRIER_NAME:
            sortedData = filterData.sort((planA, planB) =>
              planA.CarrierName.localeCompare(planB.CarrierName)
            );
            break;

          case PlanSortType.LOWEST_PREMIUM:
            sortedData = filterData.sort((planA, planB) => {
              let monthlyPremiumPlanA =
                planA.MedicalPremium + planA.DrugPremium;

              let monthlyPremiumPlanB =
                planB.MedicalPremium + planB.DrugPremium;

              return monthlyPremiumPlanA - monthlyPremiumPlanB;
            });
            break;

          default:
            break;
        }

        analytics?.track(ANALYTICS_EVENT.SEARCH_RESULTS_RETURNED, {
          quote_id: QuoteID,
          search_type: "MED_ADV_PLANS",
          search_keyword: "",
          search_filters: allFilters,
          search_sorts: [{ type: PlanSortType[PlanSort], value: "desc" }],
          search_meta: [
            {
              type: "page",
              value: 1,
            },
          ],
          search_result_count: sortedData.length,
        });

        return sortedData;
      }
    );
  }, [
    Carriers,
    MonthlyPremium,
    PlanFeatures,
    PlanSort,
    PlanSubTypes,
    DoctorNetworks,
  ]);

  const { filterData: data, isFetching: isPlanFetching } =
    useGetPlansInAreaQuery(
      {
        SessionID: SessionID ?? "",
        zipCode: location?.ZipCode ?? "",
        fips: location?.CountyFIPS ?? "",
        year: PlanYear,
        planType: PLAN_TABS_INFO[PlanTypeTab].drxID,
        effectiveDate: EffectiveDate,
        filters: PlanInAreaFilters ? PlanInAreaFilters.join(",") : undefined,
      },
      {
        skip: location === null || !SessionID,
        selectFromResult: (result) => ({
          ...result,
          filterData:
            result.data?.data !== undefined
              ? filterPlanData(result.data.data)
              : emptyArray,
        }),
      }
    );

  const providers = React.useMemo(() => {
    const providers: { [key: number]: number } = {};
    selectedDoctors.forEach((doctor) => {
      providers[doctor.providerId] = doctor.location.providerLocationId;
    });
    return providers;
  }, [selectedDoctors]);

  const { isFetching: isDoctorNetworksFetching } = useGetDoctorsNetworksQuery(
    providers,
    {
      skip: PLAN_TABS_INFO[PlanTypeTab].value === PlanTypeTabs.PDP,
    }
  );

  const [savePlan, { reset: resetSavePlan }] = useSavePlanMutation();
  const [deletePlan, { reset: resetDeletePlan }] = useDeletePlanMutation();

  const [compare, setCompare] = React.useState<ComparePlan[]>([]);
  const [openCompare, setOpenCompare] = React.useState(false);
  const [openDrugCosts, setOpenDrugCosts] = React.useState(false);
  const [planForDrugCosts, setPlanForDrugCosts] = React.useState<string>();

  const compareRef = React.useRef<ComparePlan[]>();
  compareRef.current = compare;

  const handleFavorite = async (planData: Plan, selected: boolean) => {
    if (!planData) {
      return;
    }

    if (selected) {
      await deletePlan({
        QuoteID: QuoteID ?? "",
        PlanID: planData.ID,
      });
      resetDeletePlan();

      analytics?.track(ANALYTICS_EVENT.QUOTE_FAVORITE_PLAN_REMOVED, {
        quote_id: QuoteID,
        contract_id: planData.ContractID,
        plan_id: planData.PlanID,
        segment_id: planData.SegmentID,
        plan_year: planData.PlanYear,
        carrier_id: planData.CarrierName,
        snp_type: planData.SNPType,
        plan_type: planData.PlanType,
      });
      return;
    }

    const newFavoritePlan: PlanFavorite = {
      ContractID: planData.ContractID,
      PlanID: planData.PlanID,
      SegmentID: planData.SegmentID,
      LogoURL: planData.LogoURL,
      PlanName: planData.PlanName,
      ID: planData.ID,
      PlanType: planData.PlanType,
      MedicalPremium: planData.MedicalPremium,
      DrugPremium: planData.DrugPremium,
      MarketingName: planData.MarketingName,
      CarrierName: planData.CarrierName,
      PlanYear: planData.PlanYear,
      PlanSubType: planData.PlanSubType,
      QuotedPlanPremium: planData.AnnualCalculatedPlanPremium,
      SnpType: planData.SNPType,
    };

    await savePlan({ QuoteID: QuoteID ?? "", Plan: newFavoritePlan });
    resetSavePlan();

    analytics?.track(ANALYTICS_EVENT.QUOTE_FAVORITE_PLAN_ADDED, {
      quote_id: QuoteID,
      contract_id: planData.ContractID,
      plan_id: planData.PlanID,
      segment_id: planData.SegmentID,
      plan_year: planData.PlanYear,
      carrier_id: planData.CarrierName,
      snp_type: planData.SNPType,
      plan_type: planData.PlanType,
    });
  };

  const handleCompare = (plan: ComparePlan, selected: boolean) => {
    if (!plan) {
      return;
    }

    if (selected) {
      setCompare((prev) => prev.filter((i) => i.ID !== plan.ID));

      analytics?.track(ANALYTICS_EVENT.PLAN_COMPARE_REMOVED, {
        quote_id: QuoteID,
        contract_id: plan.ContractID,
        plan_id: plan.PlanID,
        segment_id: plan.SegmentID,
        plan_year: plan.PlanYear,
        carrier_id: plan.CarrierName,
        snp_type: plan.SNPType,
        plan_type: plan.PlanType,
      });
      return;
    }

    if (compareRef.current && compareRef.current.length === maxComparePlans) {
      return;
    }

    setCompare((prev) => [
      ...prev,
      {
        ID: plan.ID,
        PlanName: plan.PlanName,
        LogoURL: plan.LogoURL,
        PlanYear: plan.PlanYear,
        ContractID: plan.ContractID,
        PlanID: plan.PlanID,
        SegmentID: plan.SegmentID,
        CarrierName: plan.CarrierName,
        SNPType: plan.SNPType,
        PlanType: plan.PlanType,
      },
    ]);

    analytics?.track(ANALYTICS_EVENT.PLAN_COMPARE_ADDED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
    });
  };

  const handleDetails = (plan: Plan, index: number) => {
    if (!plan) {
      return;
    }

    analytics?.track(ANALYTICS_EVENT.PLAN_DETAILS_CLICKED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
    });

    analytics?.track(ANALYTICS_EVENT.SEARCH_RESULT_CLICKED, {
      quote_id: QuoteID,
      search_type: "MED_ADV_PLANS",
      search_keyword: "",
      search_filters: allFilters,
      search_sorts: [{ type: PlanSortType[PlanSort], value: "desc" }],
      search_meta: [
        {
          type: "page",
          value: 1,
        },
      ],
      search_result_count: data.length,
      search_result_position_clicked: index,
      search_result_clicked_id: `${plan.PlanYear}-${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`,
    });

    navigate(`/input/compare/plan-details/${plan.ID}`);
  };

  const handleCompareClose = () => {
    setOpenCompare(false);
  };

  const handleDrugCostsClose = () => {
    setPlanForDrugCosts(undefined);
    setOpenDrugCosts(false);
  };

  const openCompareDialog = () => {
    setOpenCompare(true);

    analytics?.track(ANALYTICS_EVENT.PLAN_COMPARE_CLICKED, {
      quote_id: QuoteID,
      num_plans: compare.length,
    });
  };

  const clearComparePlans = () => {
    for (let comparePlan of compareRef.current ?? []) {
      analytics?.track(ANALYTICS_EVENT.PLAN_COMPARE_REMOVED, {
        quote_id: QuoteID,
        contract_id: comparePlan.ContractID,
        plan_id: comparePlan.PlanID,
        segment_id: comparePlan.SegmentID,
        plan_year: comparePlan.PlanYear,
        carrier_id: comparePlan.CarrierName,
        snp_type: comparePlan.SNPType,
        plan_type: comparePlan.PlanType,
      });
    }

    setCompare([]);
  };

  const handleDrugCosts = (plan: Plan) => {
    if (!plan) {
      return;
    }

    setPlanForDrugCosts(plan.ID);
    setOpenDrugCosts(true);

    analytics?.track(ANALYTICS_EVENT.PLAN_DRUG_COSTS_CLICKED, {
      quote_id: QuoteID,
      contract_id: plan.ContractID,
      plan_id: plan.PlanID,
      segment_id: plan.SegmentID,
      plan_year: plan.PlanYear,
      carrier_id: plan.CarrierName,
      snp_type: plan.SNPType,
      plan_type: plan.PlanType,
    });
  };

  const handleNavigate = (location: "doctors" | "medicines") => {
    analytics?.track(ANALYTICS_EVENT.URL_BUTTON_CLICKED, {
      quote_id: QuoteID,
      link_text: `Click Here to Select ${location}`,
      link_url: `/input/${location}`,
    });

    navigate(`/input/${location}`);
    dispatch(
      setActiveStep(location === "doctors" ? STEPS.DOCTORS : STEPS.MEDICINES)
    );
  };

  return (
    <>
      {isPlanFetching || isDoctorNetworksFetching || isPending ? (
        <Skeleton
          variant="rectangular"
          sx={{ width: { xs: 350, md: 720 }, height: 420 }}
        />
      ) : data && data.length > 0 ? (
        data.map((plan, index) => {
          if (plan.PlanType === PlanType.PDP) {
            return (
              <React.Suspense
                key={plan.ID}
                fallback={
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: { xs: 370, md: 720 }, height: 420 }}
                  />
                }
              >
                {index > 0 && index % 3 === 0 && <InfoBox key="infoBox" />}
                <PDPCard
                  id={plan.ID}
                  info={plan}
                  favorite={
                    favoritePlans.findIndex((x) => x.ID === plan.ID) > -1
                  }
                  handleFavorite={handleFavorite}
                  compare={
                    compare.findIndex(
                      (comparePlan) => comparePlan.ID === plan.ID
                    ) > -1
                  }
                  handleCompare={handleCompare}
                  handleDetails={(plan: Plan) => {
                    handleDetails(plan, index);
                  }}
                  handleDrugCosts={handleDrugCosts}
                  handleNavigate={handleNavigate}
                />
              </React.Suspense>
            );
          }

          if (plan.PlanType === PlanType.MA) {
            return (
              <React.Suspense
                key={plan.ID}
                fallback={
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: { xs: 370, md: 720 }, height: 420 }}
                  />
                }
              >
                {index > 0 && index % 3 === 0 && <InfoBox key="infoBox" />}
                <MACard
                  id={plan.ID}
                  info={plan}
                  favorite={
                    favoritePlans.findIndex((x) => x.ID === plan.ID) > -1
                  }
                  handleFavorite={handleFavorite}
                  compare={
                    compare.findIndex(
                      (comparePlan) => comparePlan.ID === plan.ID
                    ) > -1
                  }
                  handleCompare={handleCompare}
                  handleDetails={(plan: Plan) => {
                    handleDetails(plan, index);
                  }}
                  handleNavigate={handleNavigate}
                />
              </React.Suspense>
            );
          }

          return (
            <React.Suspense
              key={plan.ID}
              fallback={
                <Skeleton
                  variant="rectangular"
                  sx={{ width: { xs: 370, md: 720 }, height: 420 }}
                />
              }
            >
              {index > 0 && index % 3 === 0 && <InfoBox key="infoBox" />}
              <MAPDCard
                id={plan.ID}
                info={plan}
                favorite={favoritePlans.findIndex((x) => x.ID === plan.ID) > -1}
                handleFavorite={handleFavorite}
                compare={
                  compare.findIndex(
                    (comparePlan) => comparePlan.ID === plan.ID
                  ) > -1
                }
                handleCompare={handleCompare}
                handleDetails={(plan: Plan) => {
                  handleDetails(plan, index);
                }}
                handleDrugCosts={handleDrugCosts}
                handleNavigate={handleNavigate}
              />
            </React.Suspense>
          );
        })
      ) : (
        <Typography variant="h5">No Plans Found in Area!</Typography>
      )}

      {compare.length > 0 && (
        <CompareBar
          comparePlans={compare}
          openCompareDialog={openCompareDialog}
          clearPlans={clearComparePlans}
          handleCompare={handleCompare}
        />
      )}
      {openCompare && (
        <PlanComparisonDialog
          open={openCompare}
          handleClose={handleCompareClose}
          comparePlans={compare}
          handleDrugCosts={handleDrugCosts}
          handleNavigate={handleNavigate}
        />
      )}
      {openDrugCosts && planForDrugCosts && (
        <DrugCostsDialog
          id={planForDrugCosts}
          open={openDrugCosts}
          handleClose={handleDrugCostsClose}
        />
      )}
    </>
  );
}
