export interface MedicineInfo {
  Name: string;
  SXDG_RXCUI: number;
  DosageOptions: number;
}

export type MedicineByRXCUIBody = {
  RXCUI: string;
};

export type MedicineByRXCUIResponse = {
  success: boolean;
  data: MedicineDetails;
};

export interface SearchMedicineBody {
  SearchQuery: string;
  PageNum: number;
  Size: number;
}

export type SearchMedicineResponse = {
  success: boolean;
  data: MedicineInfo[];
};
export interface MedicineOptionsBody {
  SXDG_RXCUI: number;
}

export type MedicineOptionsResponse = {
  success: boolean;
  data: MedicineDetails[];
};

export interface SelectedMedicine {
  DosageRecordID: number;
  DosageID: string;
  SubmittedNDC: string;
  NDC: string;
  MetricQuantity: number;
  UserQuantity: number;
  DaysOfSupply: number;
  LabelName: string;
  DrugID: number;
  DrugName: string;
  DrugTypeID: number;
  DrugType: string;
  ChemicalName: string;
  PillImage: string;
}

export interface Medicine {
  DrugID: number;
  DrugName: string;
  DrugType: string;
  DrugTypeID: number;
  ChemicalName: string;
  DrugTypeNDA: string;
  SearchMatchType: number;
  ReferenceNDC: string;
  GenericDrugID?: number;
  GenericDrugName?: string;
}

interface Dosage {
  DosageID: string;
  ReferenceNDC: string;
  LabelName: string;
  PillImage: string;
  CommonUserQuantity: number;
  CommonMetricQuantity: number;
  CommonDaysOfSupply: number;
  DosageForm: string;
  DosageFormName: string;
  IsCommonDosage?: boolean;
  GenericDosageID?: string;
  GenericLabelName?: string;
  Packages?: MedicinePackage[];
}

export interface MedicinePackage {
  CommonUserQuantity: number;
  CommonMetricQuantity: number;
  PackageDescription: string;
  IsCommonPackage: boolean;
  PackageSize: number;
  PackageSizeUnitOfMeasure: string;
  PackageQuantity: number;
  TotalPackageQuantity: number;
  ReferenceNDC: string;
}

export interface MedicineDetails {
  DrugID: number;
  DrugName: string;
  DrugType: string;
  DrugTypeID: number;
  ChemicalName: string;
  DrugTypeNDA: string;
  GenericDrugID: number;
  GenericDrugName: string;
  GenericChemicalName: string;
  GenericDrugType: string;
  GenericDrugTypeID: number;
  GenericDosages: Dosage[];
  Dosages: Dosage[];
}

export interface MedicineSearchResponse {
  data: Medicine[];
}

export interface MedicineByIdResponse {
  data: MedicineDetails;
}

export enum DrugTypeIDs {
  BRAND = 1,
  GENERIC = 2,
  OTC_BRAND = 3,
  BRANDED_GENERIC = 4,
  OTC_BRANDED_GENERIC = 5,
  OTC_GENERIC = 6,
}
