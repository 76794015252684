import * as React from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  useGetMedicineDetailsQuery,
  useSaveMedicineMutation,
} from "../../../redux/services/API";

import {
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  useMediaQuery,
  CircularProgress,
  Theme,
  SelectChangeEvent,
  Grid,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Cancel from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";

import toProperCase from "../../../utils/toProperCase";
import { useRudderStackAnalytics } from "../../../utils/useRudderStackAnalytics";

import { ANALYTICS_EVENT } from "../../../config/analytics.config";

import { Medicine, MedicinePackage } from "../../../types/Medicine.types";

enum FREQUENCY {
  DAY = 1,
  WEEK = 2,
  MONTH = 30,
  MONTH_2 = 60,
  MONTH_3 = 90,
  YEAR = 365,
}

function calculateMedicineQuantities(
  frequency: FREQUENCY,
  amount: number,
  medPackage: MedicinePackage | null
) {
  let calculateMetricQuantity: number;
  switch (frequency) {
    case FREQUENCY.DAY:
      calculateMetricQuantity = medPackage
        ? medPackage.PackageSize * medPackage.PackageQuantity * (amount * 30)
        : amount * 30;

      return {
        MetricQuantity: calculateMetricQuantity,
        DaysOfSupply: 30,
      };

    case FREQUENCY.WEEK:
      calculateMetricQuantity = medPackage
        ? medPackage.PackageSize * medPackage.PackageQuantity * (amount * 4)
        : amount * 4;

      return {
        MetricQuantity: calculateMetricQuantity,
        DaysOfSupply: 30,
      };

    case FREQUENCY.MONTH:
      calculateMetricQuantity = medPackage
        ? medPackage.PackageSize * medPackage.PackageQuantity * amount
        : amount;

      return {
        MetricQuantity: calculateMetricQuantity,
        DaysOfSupply: 30,
      };

    case FREQUENCY.MONTH_2:
      calculateMetricQuantity = medPackage
        ? medPackage.PackageSize * medPackage.PackageQuantity * amount
        : amount;

      return {
        MetricQuantity: calculateMetricQuantity,
        DaysOfSupply: 60,
      };

    case FREQUENCY.MONTH_3:
      calculateMetricQuantity = medPackage
        ? medPackage.PackageSize * medPackage.PackageQuantity * amount
        : amount;

      return {
        MetricQuantity: calculateMetricQuantity,
        DaysOfSupply: 90,
      };

    case FREQUENCY.YEAR:
      calculateMetricQuantity = medPackage
        ? medPackage.PackageSize * medPackage.PackageQuantity * amount
        : amount;

      return {
        MetricQuantity: calculateMetricQuantity,
        DaysOfSupply: 365,
      };

    default:
      return { MetricQuantity: 0, DaysOfSupply: 0 };
  }
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minHeight: 600,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomDialogTitle = (props: DialogTitleProps) => {
  const { id, children, onClose } = props;

  return (
    <Box id={id} sx={{ m: 0, p: 2, bgcolor: "primary.main" }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <Cancel fontSize="large" />
        </IconButton>
      ) : null}
    </Box>
  );
};

interface RootProps {
  open: boolean;
  handleClose: () => void;
  medicine: Medicine;
}

export default function AddMedicineDialog({
  open,
  handleClose,
  medicine,
}: RootProps) {
  const { SessionID, QuoteID } = useAppSelector((state) => state.Quote);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only("xs")
  );

  const analytics = useRudderStackAnalytics();

  const [useGeneric, setUseGeneric] = React.useState("N");
  const [frequency, setFrequency] = React.useState<FREQUENCY>(FREQUENCY.DAY);
  const [amount, setAmount] = React.useState<number>(0);
  const [dosageIndex, setDosageIndex] = React.useState<number>(-1);
  const [packageIndex, setPackageIndex] = React.useState<number>(-1);

  const { data, isFetching } = useGetMedicineDetailsQuery(
    useGeneric === "N" ? medicine.DrugID : medicine.GenericDrugID ?? ""
  );
  const medicineDetails = data?.data;

  const [
    saveMedicine,
    {
      isLoading: isSavingMedicine,
      isSuccess: isSaveSuccess,
      reset: resetSaveMedicine,
    },
  ] = useSaveMedicineMutation();

  React.useEffect(() => {
    if (isSaveSuccess) {
      handleClose();
    }
  }, [isSaveSuccess, handleClose]);

  React.useEffect(() => {
    if (!medicineDetails) {
      return;
    }

    for (let i = 0; i < medicineDetails.Dosages.length; i++) {
      const dosage = medicineDetails.Dosages[i];
      if (dosage.IsCommonDosage) {
        setDosageIndex(i);
        setAmount(dosage.CommonUserQuantity);
        setFrequency(dosage.CommonDaysOfSupply);

        if (dosage.Packages) {
          for (let j = 0; j < dosage.Packages.length; j++) {
            const dosagePackage = dosage.Packages[j];
            if (dosagePackage.IsCommonPackage) {
              setPackageIndex(j);
              break;
            }
          }
        }

        break;
      }
    }
  }, [medicineDetails]);

  const handleAddMedicine = (
    NDC: string,
    DaysOfSupply: number,
    MetricQuantity: number
  ) => {
    saveMedicine({
      SessionID: SessionID ?? "",
      NDC,
      MetricQuantity: MetricQuantity.toString(),
      DaysOfSupply: DaysOfSupply.toString(),
    });
    resetSaveMedicine();
  };

  const addNewMedicine = () => {
    if (!amount || dosageIndex === -1 || !medicineDetails) {
      return;
    }

    const dosage = medicineDetails.Dosages[dosageIndex];
    const dosagePackage = dosage.Packages
      ? dosage.Packages[packageIndex]
      : null;

    const NDC = dosagePackage
      ? dosagePackage.ReferenceNDC
      : dosage.ReferenceNDC;

    const { DaysOfSupply, MetricQuantity } = calculateMedicineQuantities(
      frequency,
      amount,
      dosagePackage
    );

    handleAddMedicine(NDC, DaysOfSupply, MetricQuantity);

    analytics?.track(ANALYTICS_EVENT.QUOTE_MEDICINE_ADDED, {
      quote_id: QuoteID,
      medicine_ndc: NDC,
      medicine_name: medicineDetails.DrugName,
      medicine_dosage: dosage.LabelName,
      medicine_quantity: MetricQuantity,
      medicine_day_supply: DaysOfSupply,
      medicine_generic: useGeneric === "Y",
    });
  };

  const handleGenericChange = (value: string) => {
    setUseGeneric(value);
    setDosageIndex(-1);
    setPackageIndex(-1);
  };

  const handleDosageChange = (e: SelectChangeEvent<number>) => {
    setDosageIndex(e.target.value as number);
    setPackageIndex(-1);
  };

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="add-medicine-dialog-title"
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h6" fontWeight={600} color="#fff">
          Add Medicine
        </Typography>
      </CustomDialogTitle>
      <Grid container rowGap={2} p={2}>
        {medicine.GenericDrugID && (
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="secondary"
              value={useGeneric}
              exclusive
              onChange={(_e, value) => {
                handleGenericChange(value);
              }}
              aria-label="brand or generic version?"
            >
              <ToggleButton value="N">Brand</ToggleButton>
              <ToggleButton value="Y">Generic</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}

        <Grid item xs={12} container alignItems="center" gap={2}>
          <Typography variant="h5" textAlign="left" fontWeight={600}>
            {toProperCase(
              useGeneric === "N"
                ? medicine.DrugName
                : medicine.GenericDrugName ?? ""
            )}
          </Typography>
          <Chip
            label={
              isFetching ? (
                <CircularProgress size="16px" color="inherit" />
              ) : (
                medicineDetails?.DrugType
              )
            }
            color="secondary"
            sx={{ color: "#fff", fontWeight: 600 }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignContent="space-between"
        rowGap={3}
        p={2}
        minHeight={300}
        position="relative"
      >
        {isFetching ? (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            <CircularProgress />
          </Grid>
        ) : !medicineDetails ? (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            <Typography>Error: Try Again</Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Which dosage do you take?</FormLabel>
                <Select<number>
                  value={dosageIndex}
                  onChange={handleDosageChange}
                  fullWidth
                >
                  <MenuItem value={-1} />

                  {medicineDetails.Dosages.map((dosage, index) => (
                    <MenuItem key={`dosage-${dosage.DosageID}`} value={index}>
                      {dosage.LabelName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {dosageIndex > -1 &&
              medicineDetails.Dosages[dosageIndex].Packages && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormLabel>Which package?</FormLabel>
                    <Select<number>
                      value={packageIndex}
                      onChange={(e) =>
                        setPackageIndex(e.target.value as number)
                      }
                      fullWidth
                    >
                      <MenuItem value={-1} />

                      {medicineDetails.Dosages[dosageIndex].Packages?.map(
                        (dosagePackage, index) => (
                          <MenuItem
                            key={`package-${dosagePackage.ReferenceNDC}`}
                            value={index}
                          >
                            {dosagePackage.PackageQuantity} x{" "}
                            {dosagePackage.PackageSize}{" "}
                            {dosagePackage.PackageSizeUnitOfMeasure}{" "}
                            {dosagePackage.PackageDescription}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Grid
                  container
                  gap={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormLabel>
                    How many times do you take the medicine?
                  </FormLabel>
                  <TextField
                    type="number"
                    sx={{ width: 100 }}
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                  <Typography fontWeight={500}>per</Typography>
                  <Select<FREQUENCY>
                    sx={{ width: 150 }}
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value as FREQUENCY)}
                  >
                    <MenuItem value={FREQUENCY.DAY}>Day</MenuItem>
                    <MenuItem value={FREQUENCY.WEEK}>Week</MenuItem>
                    <MenuItem value={FREQUENCY.MONTH}>Month</MenuItem>
                    <MenuItem value={FREQUENCY.MONTH_2}>2 Months</MenuItem>
                    <MenuItem value={FREQUENCY.MONTH_3}>3 Months</MenuItem>
                    <MenuItem value={FREQUENCY.YEAR}>Year</MenuItem>
                  </Select>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12} container justifyContent="center">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => addNewMedicine()}
                loading={isSavingMedicine}
              >
                Add Medicine
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    </CustomDialog>
  );
}
