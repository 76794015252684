import { format } from "date-fns";

export default function EffectiveDates(planYear: number) {
  const NOW = new Date();

  if (planYear > NOW.getFullYear()) {
    return [
      {
        value: format(new Date(NOW.getFullYear() + 1, 0, 1), "M/d/yyyy"),
        label: format(new Date(NOW.getFullYear() + 1, 0, 1), "LLLL yyyy"),
      },
    ];
  }

  return [
    {
      value: format(
        new Date(NOW.getFullYear(), NOW.getMonth() + 1, 1),
        "M/d/yyyy"
      ),
      label: format(
        new Date(NOW.getFullYear(), NOW.getMonth() + 1, 1),
        "LLLL yyyy"
      ),
    },
    {
      value: format(
        new Date(NOW.getFullYear(), NOW.getMonth() + 2, 1),
        "M/d/yyyy"
      ),
      label: format(
        new Date(NOW.getFullYear(), NOW.getMonth() + 2, 1),
        "LLLL yyyy"
      ),
    },
    {
      value: format(
        new Date(NOW.getFullYear(), NOW.getMonth() + 3, 1),
        "M/d/yyyy"
      ),
      label: format(
        new Date(NOW.getFullYear(), NOW.getMonth() + 3, 1),
        "LLLL yyyy"
      ),
    },
  ];
}
